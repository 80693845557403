'use client';
import { type PropsWithChildren, createContext, useContext } from 'react';

import { useCountry } from '@/layers/core/deal/services/CountryContext';

const Context = createContext<string | undefined>(undefined);

export const useLangProvider = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useLangProvider must be used within a LangProvider');
  }
  return context;
};

export function LangProvider({
  children,
  value,
}: PropsWithChildren<{ value?: string }>) {
  const { lang } = useCountry();
  return <Context.Provider value={value ?? lang}>{children}</Context.Provider>;
}
