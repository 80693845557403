import { useDebugValue } from 'react';

import { atom, useAtomValue } from 'jotai';

import { userAtom } from '@quick/auth';

const userDetailsAtom = atom((get) => {
  const { token, sub, exp } = get(userAtom) ?? {};
  return {
    token,
    id: sub,
    exp,
  };
});

const useUser = () => {
  const user = useAtomValue(userDetailsAtom);
  useDebugValue(userDetailsAtom);
  return user;
};

export { useUser };
