'use client';

import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { envs } from '@/layers/core/data/const';
import { codes, coins } from '@/layers/core/data/country';
import { countryMap } from '@/layers/core/domain/countryMap';

type ICountryContext = (typeof envs.hosts.urls)['CO'] &
  (typeof envs.hosts.defaults)['CO'] & {
    coin: string;
    id: number;
    code: string;
  };

type RecursiveNonNullable<T> = {
  [K in keyof T]-?: RecursiveNonNullable<NonNullable<T[K]>>;
};

const Context = createContext<ICountryContext | undefined>(
  process.env.NODE_ENV === 'test'
    ? {
        lang: 'es',
        clean: false,
        site: '',
        support: '',
        contact: '',
        pqr: '',
        ...envs.hosts.defaults['CO'],
        coin: 'COP',
        id: 1,
        country: 'CO',
        code: '57',
      }
    : undefined
);

export function CountryProvider({ children }: PropsWithChildren) {
  const [data] = useState(() =>
    typeof window !== 'undefined' ? window.location.hostname : undefined
  );

  const domain = useMemo(
    () => envs.hosts.urls[data as string] ?? { country: 'CO' },
    [data]
  );
  const defaults = envs.hosts.defaults[domain.country];

  const coin = coins[domain.country];
  const id = countryMap[domain.country];
  const code = codes[domain.country];

  const value = useMemo(
    () =>
      Object.assign(
        { lang: 'es', clean: false, ...defaults },
        { coin, id, code, ...domain }
      ) as RecursiveNonNullable<ICountryContext>,
    [domain, defaults, code, coin, id]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useCountry = () => {
  const value = useContext(Context);
  if (!value) throw new Error('useCountry must be used inside CountryProvider');
  return value as RecursiveNonNullable<ICountryContext>;
};
