export const countryMap = {
  CO: 1,
  CL: 2,
  MX: 3,
  US: 4,
} as const;

type CountryNames = keyof typeof countryMap;

export const idToCountry = Object.entries(countryMap).reduce(
  (prev, [key, value]) => {
    prev[value] = key as CountryNames;
    return prev;
  },
  {} as Record<(typeof countryMap)[CountryNames], CountryNames>
);
