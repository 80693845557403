import type { LatLngLiteral } from 'leaflet';

import { Lines } from '@quick/go-api-types/typesAndModalities';

import { countryMap } from '@/layers/core/domain/countryMap';

type countries = keyof typeof countryMap;

export type defaultPointType = {
  center: LatLngLiteral;
  zoom: number;
};

export const COPoint: defaultPointType = {
  center: { lat: 4.6577375, lng: -74.0932835 },
  zoom: 6,
};
export const USPoint: defaultPointType = {
  center: { lat: 26.2410656, lng: -80.0743557 },
  zoom: 9,
};
export const MXPoint: defaultPointType = {
  center: { lat: 22.8434133, lng: -101.5106434 },
  zoom: 6.5,
};
export const CLPoint: defaultPointType = {
  center: { lat: -33.447487, lng: -70.673676 },
  zoom: 5,
};

const points: Record<countries, defaultPointType> = {
  CO: COPoint,
  MX: MXPoint,
  CL: CLPoint,
  US: USPoint,
};

const coins: Record<countries, string> = {
  CO: 'COP',
  MX: 'MXN',
  CL: 'CLP',
  US: 'USD',
};

const codes: Record<countries, string> = {
  CO: '57',
  MX: '52',
  US: '1',
  CL: '56',
};

const declaredValueLimits: Record<
  countries,
  Record<ValueOf<typeof Lines>, { min: number; max: number }>
> = {
  CO: {
    [Lines.global]: { min: 0, max: 0 },
    [Lines.demand]: { min: 100_000, max: 350_000_000 },
    [Lines.lastMille]: { min: 0, max: 1_200_000 },
  },
  CL: {
    [Lines.global]: { min: 0, max: 0 },
    [Lines.demand]: { min: 1_000, max: 10_200_000 }, // TODO: Define values
    [Lines.lastMille]: { min: 0, max: 100_200 },
  },
  US: {
    [Lines.global]: { min: 0, max: 0 },
    [Lines.demand]: { min: 100, max: 100_000_000 },
    [Lines.lastMille]: { min: 0, max: 5_000 },
  },
  MX: {
    [Lines.global]: { min: 0, max: 0 },
    [Lines.demand]: { min: 100, max: 10_000 }, // TODO: Define values
    [Lines.lastMille]: { min: 0, max: 300 },
  },
};

const defaultCities: Record<countries, number> = {
  CO: 1,
  CL: 1508,
  MX: 1519,
  US: 399,
};

export { codes, coins, declaredValueLimits, defaultCities, points };
