export const ServiceType = {
  moto: 1, // Mensajería
  carry: 2,
  delivery: 3, // Domicilio
  cargo: 6,
  distribution: 7,
  global: 1000,
  'last mille': 1001,
} as const;

export const Modality = {
  day: 1,
  halfDay: 2,
  errand: 3,
} as const;

export const WaresType = {
  dry: 1,
  cold: 2,
  frozen: 3,
} as const;

export const PaymentMethod = {
  test: 0,
  cash: 1,
  bag: 2,
  card: 3,
  pse: 4,
  credit: 5,
  online: 6,
} as const;

export const Lines = {
  global: 1,
  demand: 2,
  lastMille: 3,
} as const;
//TO DO: Agregar enterprise 4

export const serviceToLine = {
  [ServiceType.cargo]: Lines.demand,
  [ServiceType.distribution]: Lines.demand,
  [ServiceType.moto]: Lines.lastMille,
  [ServiceType.carry]: Lines.lastMille,
  [ServiceType.delivery]: Lines.lastMille,
  [ServiceType['last mille']]: Lines.lastMille,
  [ServiceType.global]: Lines.global,
} as const;
