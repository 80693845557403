import axios from 'axios';

import { envs } from '@/layers/core/data/const';

const axios_demand = axios.create({
  baseURL: envs.quickApi,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export { axios_demand };
